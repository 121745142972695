// extracted by mini-css-extract-plugin
export var active = "project-section-module--active--bd88e";
export var categoryText = "project-section-module--categoryText--729e9";
export var container = "project-section-module--container--bc601";
export var filters = "project-section-module--filters--16d19";
export var headerDesc = "project-section-module--headerDesc--06da8";
export var overlay = "project-section-module--overlay--fc6b2";
export var portfolioFilter = "project-section-module--portfolioFilter--ef08c";
export var portfolioGrid = "project-section-module--portfolioGrid--38b86";
export var portfolioItem = "project-section-module--portfolioItem--a6cb9";
export var section = "project-section-module--section--2dbef";
export var sectionHeader = "project-section-module--sectionHeader--37741";
export var textBtn = "project-section-module--textBtn--e7907";